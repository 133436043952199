import '../../../vendor/silentLogin';
import { canJoinOnWeb } from '../helpers/EnvironmentHelper';
import { CommonProperty, logger } from '../helpers/LoggerHelper';
import { silentLoginAsync, SilentLoginResult } from '../helpers/SilentLoginHelper';
import { triggerFallbackFlow } from './FallbackFlow';
import './meetnow.less';
import { processMeetNowEdgeAuthenticatedRequest, processMeetNowEdgeGuestRequest } from './MeetNowEdgeHandler';
import getMeetNowSettings from './MeetNowSettings';

const stopInitialContentBlinking = () => {
    const container = document.getElementById('meetnowContainer');
    if (container) {
        container.removeAttribute('style');
    }
};

try {
    stopInitialContentBlinking();

    const settings = getMeetNowSettings();
    logger.init(settings.MeetNowEdgeAriaTenantId, 'meetnow_edge_');
    logger.sessionStarted({ useUnifiedView: false, wasPreLoggedin: false });

    silentLoginAsync()
        .then((loginResult: SilentLoginResult | undefined) => {
            try {
                if (!loginResult || !loginResult.skypetoken) {
                    logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: false });
                    logger.initCommonProperty({
                        key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                        value: canJoinOnWeb,
                    });
                    logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
                    processMeetNowEdgeGuestRequest(settings);
                } else {
                    logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: true });
                    logger.initCommonProperty({
                        key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                        value: canJoinOnWeb,
                    });
                    logger.addSkypeIdAndMsaCidHexCommonProperties(loginResult.skypetoken, false);

                    logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
                    processMeetNowEdgeAuthenticatedRequest(settings, loginResult);
                }
            } catch (error) {
                triggerFallbackFlow(error, 'error from a regular flow');
            }
        })
        .catch((silentLoginError) => {
            logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: false });
            logger.initCommonProperty({
                key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                value: canJoinOnWeb,
            });
            logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
            try {
                processMeetNowEdgeGuestRequest(settings);
            } catch (error) {
                triggerFallbackFlow(error, `silent login failure: ${silentLoginError.error}`);
            }
        })
        .catch((generalError) => {
            // It is possible that in async flow try/catch above won't actually catch an error so this is a precaution
            triggerFallbackFlow(generalError, 'Error triggered by guest flow after a failed silent login');
        });
} catch (error) {
    triggerFallbackFlow(error, 'general error');
}
